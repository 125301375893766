import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reviews from './Reviews'
import buildUrl from '../../utils/apiHelpers'
import { getFetchWrapper } from '../../src/fetchHelpers'

function applyUpdateResult (data) {
  return function (prevState) {
    return {
      hits: [...prevState.hits, ...data.hits],
      page: data.page,
      isLoading: false
    }
  }
}

function applySetResult (data) {
  return {
    hits: data.hits,
    page: data.page,
    isLoading: false
  }
}

class SellerReviews extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hits: [],
      perPage: 3,
      page: 1,
      isLoading: false,
      error: false,
      errorMsg: ''
    }
    this.handlePaginatedDisplay = this.handlePaginatedDisplay.bind(this)
  }

  componentDidMount () {
    this.setState({ isLoading: true })
    this.fetchReviews(this.props.sellerId, 1)
  }

  handlePaginatedDisplay (e) {
    this.setState({ errorMsg: '', error: false, isLoading: true })
    setTimeout(function () {
      this.fetchReviews(this.props.sellerId, +this.state.page + 1)
    }.bind(this), 1000)
  }

  fetchReviews (sellerId, page) {
    return getFetchWrapper(buildUrl('/api/v1/reviews.json?per_page=' + this.state.perPage + '&page=' + page + '&seller_id=' + sellerId))
      .then(response => response.json())
      .then(data => {
        this.onSetResult(data, page)
      })
      .catch(error => {
        console.log(error)
        this.setState({ errorMsg: 'Sorry, there was an error loading the reviews.', error: true, isLoading: false })
      })
  }

  onSetResult (data, page) {
    return page === 1
      ? this.setState(applySetResult(data))
      : this.setState(applyUpdateResult(data))
  }

  render () {
    const innerTextButton = (this.state.hits.length === 0 && this.state.error) ? 'Load reviews' : 'View more reviews'
    return (
      <div>
        <Reviews reviewsToRender={this.state.hits} />
        <p className='mb-16 text-center'>{this.state.errorMsg}</p>
        {this.state.isLoading &&
          <div className='text-center m-16'>
            <div className='vsd-listing_reviews-loading'>
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>}
        {(this.state.page * this.state.perPage >= this.props.numberOfReviews || this.state.isLoading)
          ? ('')
          : (
            <div className='text-center my-32'>
              <button value={this.state.page} onClick={this.handlePaginatedDisplay} className='vsd-button__outlined'>
                {innerTextButton}
              </button>
            </div>
            )}
      </div>
    )
  }
}

SellerReviews.propTypes = {
  sellerId: PropTypes.number,
  numberOfReviews: PropTypes.number
}

export default SellerReviews
