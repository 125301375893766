import '../../css/styleguide_v2'
import initReactComponents from '../../application/v2_react_components'
import setAccordions from '../../src/accordions'
import setBreadcrumbsScrollPosition from '../../src/breadcrumbs'
import setTruncatedSections from '../../src/truncatedSections'
import { setEnquiryModal } from '../../src/enquiryModal'
import customRadioButtons from '../../src/customRadioButtons'
import customCheckboxButtons from '../../src/customCheckboxButtons'
import customiseSelect from '../../src/customSelect'
import { setModals } from '../../src/modals'
import closeNotificationsOnClick from '../../src/notifications'
import closePostRegModalOnSubmit from '../../src/postRegModal'

import initNavBarMenus from '../../src/navBarMenus'
import newsLetterSignUp from '../../src/newsLetterSignUp'
import initMobile from '../../libs/navbar/mobile'
import searchAutoComplete from '../../src/algolia_search/searchAutocomplete'

import setItemConfirmationForm from '../../src/itemConfirmationForm'
import lineItemConfirmationFormValidation from '../../src/form_validations/lineItemConfirmationFormValidation'
import reviewFormValidation from '../../src/form_validations/reviewFormValidation'

import setDispatchForm from '../../src/itemDispatchForm'
import itemDispatchFormValidation from '../../src/form_validations/itemDispatchFormValidation'

import initAnalytics from '../../src/analytics'
import initSentry from '../../libs/sentry'
import initPurchaseThankYou from '../../libs/purchase/thankyou'

import Rails from '@rails/ujs'

require.context('../../images', true)

initReactComponents()

document.addEventListener('DOMContentLoaded', () => {
  Rails.start()
  initSentry()
  initAnalytics()
  setAccordions()
  setBreadcrumbsScrollPosition()
  setTruncatedSections()
  setEnquiryModal()
  customRadioButtons()
  customCheckboxButtons()
  customiseSelect()
  setModals()
  closeNotificationsOnClick()
  newsLetterSignUp()
  initNavBarMenus()
  searchAutoComplete()
  initMobile()
  setItemConfirmationForm()
  lineItemConfirmationFormValidation()
  reviewFormValidation()
  setDispatchForm()
  itemDispatchFormValidation()
  initPurchaseThankYou()
  closePostRegModalOnSubmit()

  import(/* webpackChunkName: "carousels" */ '../../src/carousels').then(module => {
    module.initTopBannerCarousel()
  })

  const customSelect = () => {
    customiseSelect()
  }

  window.customSelect = customSelect
  window.setAccordions = setAccordions
})
